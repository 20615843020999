import Register from './views/Register.vue';
import Reports from './views/reports/Reports.vue';
import Logs from './views/logs/Logs.vue';
import Layout from './views/Layout.vue';

export default [
  {
    path: '/',
    name: 'reports',
    component: Layout,
    children: [
      {
        path: '',
        name: 'reports',
        component: Reports,
      },
      {
        path: 'logs',
        name: 'logs',
        component: Logs,
      },
    ],
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
];
