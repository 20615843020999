<!-- eslint-disable max-len -->
<template>
  <div class="mb-10 flex flex-col">
    <div class="flex pb-5">
      <h1 class="text-xl text-color font-semibold page-title pt-2">
        {{ $t('voice.logs.title') }}
        <el-popover
          popper-class="voice-info"
          placement="right"
          width="320"
          trigger="hover"
          :title="$t('voice.logs.title')"
          >
          <i
            class="icon-novo-info-circle text-base font-semibold cursor-pointer"
            slot="reference"
          />
          <div class="el-popover__content">
            {{ $t('voice.logs.index[0]') }}
          </div>
        </el-popover>
      </h1>
      <div class="ml-auto">
        <el-button
          type="primary"
          @click.prevent="showModal = true"
        >
          <i class="icon-novo-download mr-1 align-middle" />
          {{ $t('actions.export') }}
        </el-button>
      </div>
    </div>
    <div class="flex -mx-3">
      <div class="px-3 w-full">
        <div class="bg-white border-grey-lighter border solid flex flex-col w-full relative rounded bg-grey-inputs">
          <Filters />
          <Table />
          <Pagination />
        </div>
      </div>
    </div>

    <ExportLogsModal :showModal="showModal" @close-modal="closeExportModal" />
  </div>
</template>

<script>
import Moment from 'moment';
import {
  mapActions,
  mapMutations,
  mapGetters,
  mapState,
} from 'vuex';

import directionJson from '@/json/direction.json';
import statusJson from '@/json/status.json';

import Table from './partials/Table.vue';
import Pagination from './partials/Pagination.vue';
import Filters from './partials/Filters.vue';
import ExportLogsModal from './partials/ExportLogsModal.vue';

export default {
  name: 'Reports',

  components: {
    Filters,
    Table,
    Pagination,
    ExportLogsModal,
  },

  data() {
    const defaultDaterange = [
      Number(Moment().startOf('day').subtract(1, 'w')),
      Number(Moment().endOf('day')),
    ];

    return {
      showModal: false,
      directionJson,
      statusJson,
      defaultDaterange,
      loading: false,

      debounceDefault: 500,

      querySearch: '',
    };
  },

  computed: {
    ...mapGetters({
      cursors: 'logs/getCursors',
      logs: 'logs/getLogs',
      filters: 'logs/getFilters',
    }),

    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  created() {
    this.fetchSubaccounts();
    this.getCountriesByAccount();
    this.getUser();
  },

  methods: {
    ...mapActions({
      getCountriesByAccount: 'common/getCountriesByAccount',
      getSubAccounts: 'common/fetchSubAccounts',
      getLogs: 'logs/fetchLogs',
      getUser: 'user/fetchUser',
    }),

    ...mapMutations({
      setFilters: 'common/SET_CDR_FILTERS',
      setPagination: 'logs/SET_PAGINATION',
    }),

    closeExportModal() {
      this.showModal = false;
    },

    handleCurrentChange(val) {
      this.setPage(val);
    },

    updateFilter(props) {
      this.setFilters({
        ...this.filters,
        ...props,
        dateOffset: this.timeZoneOffset,
      });
    },

    prevPage() {
      const prevPage = this.pagination.index - 1;
      const { limit } = this.filters;

      if (this.pagination.index > 1) {
        this.setFilters({
          ...this.filters,
          pageDirection: 1,
          pageOffset: limit * (prevPage - 1),
          dateOffset: this.timeZoneOffset,
        });

        this.fetchData();
      }
    },

    nextPage() {
      const nextPage = this.pagination.index + 1;
      const { limit } = this.filters;

      this.setFilters({
        ...this.filters,
        pageDirection: 0,
        pageOffset: limit * (nextPage - 1),
        dateOffset: this.timeZoneOffset,
      });

      this.fetchData();
    },

    async fetchData() {
      this.loading = true;

      await this.getLogs({
        ...this.filters,
        dateOffset: this.timeZoneOffset,
      });
    },

    async fetchSubaccounts() {
      this.loading = true;
      try {
        await this.getSubAccounts();
        this.fetchData();
      } catch (err) {
        // this.showMessage(err.message, 'error');
        this.$showError(this, err);
        this.loading = false;
      }
    },

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },
  },
};
</script>

<style lang="scss">
// Add this override to hide border in tabs for this page
.reports-tab .el-tabs__nav-wrap::after {
  height: 0px;
  background: none;
}

.voice-info {
  word-break: break-word !important;
  padding: 0 !important;

  .el-popover__title {
    padding: 12px;
    border-bottom: 1px solid #f0f0f0;
  }

  .el-popover__content {
    padding: 0 12px 12px;
  }
}

</style>
