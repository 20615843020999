<template>
  <div class="filter-item">
    <el-dropdown
      :ref="refString"
      trigger="click"
      size="small"
      type="primary"
      class="h-full"
      :hide-on-click="false"
      @visible-change="handleVisibleChange"
      @command="highlightOption"
      >
      <div
        class="px-4 h-full flex items-center font-normal text-sm"
        :style="{
          'background-color': selectedOption ? '#CCDDF9' : '#F1F1F1',
          'border-radius': '.4rem'
        }"
      >
        <span class="mr-2" style="color: #5b5b5b">
          <span v-if="selectedOption">
            <span>{{ filterName }} </span>
            <strong>is </strong>
            <span>{{ selectedOption }}</span>
          </span>
          <span v-else>{{ filterName }}</span>
        </span>
        <i class="icon-novo-arrow-down text-base font-semibold" />
      </div>
      <el-dropdown-menu slot="dropdown">
        <div class="px-2 py-1">
          <el-input
            v-model="searchInput"
            class="w-full"
            prefix-icon="icon-novo-search text-xl"
            :placeholder="`Search ${filterName.toLowerCase()}`"
          />
        </div>
        <div class="filter-options">
          <div v-if="!filteredOptions.length" class="flex justify-center p-3 text-sm text-grey">
            No matching data
          </div>
          <el-dropdown-item
            v-for="item in filteredOptions"
            :key="item.value"
            :command="item.value"
            class="flex justify-between items-center"
          >
            {{ item.name }}
            <i
              v-if="highlightedOption === item.value"
              class="icon-novo-check text-lg font-semibold"
            />
          </el-dropdown-item>
        </div>
        <div class="flex justify-between px-3 pt-2 border-t border-grey-lighter">
          <el-button type="text" size="small" style="color: #CB2233" @click="remove">
            Remove
          </el-button>
          <el-button
            type="primary"
            size="small"
            @click="save"
            :disabled="!highlightedOption"
          >
            Done
          </el-button>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>

export default {
  props: {
    refString: {
      type: String,
      required: true,
    },
    filterName: {
      type: String,
      required: true,
    },
    selectedOption: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    updateSelection: {
      type: Function,
      required: true,
    },
  },

  data: (instance) => {
    const { options } = instance;

    return {
      searchInput: '',
      highlightedOption: '',
      filteredOptions: options,
    };
  },

  watch: {
    searchInput(v) {
      this.filteredOptions = this.options.filter(
        p => p.name.toLowerCase().includes(v.toLowerCase()),
      );
    },
  },

  methods: {
    highlightOption(v) {
      this.highlightedOption = v;
    },

    handleVisibleChange(v) {
      if (!v) {
        this.searchInput = '';
        this.highlightedOption = '';
      }
    },

    remove() {
      this.$emit('update-selection', this.refString, '');
      this.$refs[this.refString].hide();
    },

    save() {
      this.$emit('update-selection', this.refString, this.highlightedOption);
      this.$refs[this.refString].hide();
    },
  },
};
</script>

<style scoped>
.filter-item {
  padding: 0.25rem;
  height: 2.75rem;
}

.filter-options {
  max-height: 250px;
  overflow-y: auto;
}

.el-dropdown-menu--small .el-dropdown-menu__item {
  padding: 0.5rem 1rem;
}
</style>
