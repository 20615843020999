import moment from 'moment';
import http from '../../../utils/http';

// this is from server. putting this here so server doesn't timeout.
const mapReportsData = (data) => {
  const { recordset } = data;
  const chartData = {
    Incoming: {
      data: [],
    },
    Outgoing: {
      data: [],
    },
  };

  const total = {
    Incoming: {
      calls: 0,
      durationSeconds: 0,
    },
    Outgoing: {
      calls: 0,
      durationSeconds: 0,
    },
  };

  // eslint-disable-next-line no-plusplus
  for (let i = 0, len = recordset.length; i < len; i++) {
    const current = recordset[i];
    const date = moment(current.Date).toISOString();
    chartData.Incoming.data.push({
      date,
      value: current.Incoming,
    });
    chartData.Outgoing.data.push({
      date,
      value: current.Outgoing,
    });

    total.Incoming.calls += current.Incoming || 0;
    total.Outgoing.calls += current.Outgoing || 0;

    total.Incoming.durationSeconds += current.IncomingSec || 0;
    total.Outgoing.durationSeconds += current.OutgoingSec || 0;
  }

  return {
    chart: chartData,
    total,
    grid: recordset,
  };
};

export default {
  // Fetch reports
  fetchReports: async ({ commit }, payload = {}) => {
    try {
      const params = {
        ...payload,
        daterange: payload.daterange.map(dt => moment(dt).utcOffset(0, true).unix()),
      };
      const queryString = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
      const { data } = await http.get(`/voice/reports?${queryString}`);
      const mappedData = mapReportsData(data);
      commit({ type: 'FETCH_REPORTS', reports: mappedData });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
