<script>
import { Line, mixins } from 'vue-chartjs';

export default {
  name: 'LineChart',

  extends: Line,

  mixins: [mixins.reactiveProp],

  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default() {
        return {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'right',
            labels: {
              boxWidth: 13,
            },
          },
        };
      },
    },
  },

  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
