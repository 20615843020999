import Vue from 'vue';
import Vuex from 'vuex';

import report from './modules/report';
import common from './modules/common';
import user from './modules/user';
import logs from './modules/logs';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  state: {
    errors: [],
  },

  mutations: {
    API_ERROR(state, payload) {
      // if (window.Bugsnag) {
      //   window.Bugsnag.notify(payload);
      // }
      state.errors.push(payload); // eslint-disable-line no-param-reassign
    },
  },

  actions: {},

  modules: {
    report,
    common,
    user,
    logs,
  },

  strict: debug,
});

export default store;
