<!-- eslint-disable max-len -->
<template>
  <div class="flex-shrink border-b border-grey-lighter">
    <div>
      <div class="flex px-3 py-2">
        <div class="w-3/5 flex-grow">
          <el-input
            v-model="searchInput"
            class="w-full"
            prefix-icon="icon-novo-search text-xl"
            :placeholder="$t('actions.search')"
            @keyup.native="searchTraffic"
          />
        </div>
        <div class="px-5 flex-auto flex items-center">
          <el-badge
            class="filter"
            :value="filterCount"
            type="warning"
            :hidden="!filterCount"
          >
            <span
              tabindex="0"
              :class="`cursor-pointer icon-novo-filter text-2xl p-1 hover-icon ${isMoreFilterVisible ? 'bg-grey-lighter rounded' : ''}`"
              @click="isMoreFilterVisible = !isMoreFilterVisible"
            />
          </el-badge>
          <span
            v-if="paginatedLogs.length"
            class="ml-6 text-base"
          >{{ filteredLogs.length.toLocaleString() }} {{ $t('app_labels.results') }}</span>
        </div>
        <div class="flex-shrink px-4 border-grey-lighter border-l flex items-center">
          <el-tooltip
            :content="$t('actions.refresh')"
            placement="top"
          >
            <span
              class="icon-novo-refresh text-2xl cursor-pointer hover-icon"
              @click="refresh"
            />
          </el-tooltip>
        </div>
      </div>

      <div v-if="isMoreFilterVisible" class="flex items-baseline justify-between border-t border-grey-lighter">
        <div class="flex py-3 px-3 flex-wrap">
          <!-- Date Filter -->
          <div class="filter-item">
            <el-dropdown
              ref="dateRange"
              trigger="click"
              size="small"
              type="primary"
              class="h-full"
              @visible-change="handleDateVisibility"
            >
              <div
                class="px-4 h-full flex items-center font-normal text-sm"
                style="background-color: #CCDDF9; border-radius: .4rem;"
              >
                <span class="mr-2" style="color: #5b5b5b">
                  <span>Date range </span>
                  <strong>is </strong>
                  <span>{{ `${formatDate(dates[0])} – ${formatDate(dates[1])}` }}</span>
                </span>
                <i class="icon-novo-arrow-down text-base font-semibold" />
              </div>
              <el-dropdown-menu
                slot="dropdown"
                class="invisible"
              >
                <CustomPicker
                  :show-tz="false"
                  :loading="loading"
                  :user="user"
                  :date-range="dateRange"
                  :showPicker="showDatePicker"
                  @change="dateRangeHandler"
                />
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <!-- Subaccount  -->
          <FilterDropdown
            v-if="activeFilters.includes('subaccount')"
            ref="subaccountFilter"
            refString="subaccount"
            :filterName="$t('fields.subaccount')"
            :selectedOption="filterSelections.subaccount"
            :options="subaccounts"
            @update-selection="updateSelection"
          />

          <!-- Call Quality Score (MOS) -->
          <FilterDropdown
            v-if="activeFilters.includes('callQualityScore')"
            refString="callQualityScore"
            filterName="Call quality score (MOS)"
            :selectedOption="filterSelections.callQualityScore"
            :options="callQualityScoresJson"
            @update-selection="updateSelection"
          />

          <!-- Product Type -->
          <FilterDropdown
            v-if="activeFilters.includes('productType')"
            refString="productType"
            :filterName="$t('fields.product')"
            :selectedOption="filterSelections.productType"
            :options="productTypesJson"
            @update-selection="updateSelection"
          />

          <!-- Direction -->
          <FilterDropdown
            v-if="activeFilters.includes('direction')"
            refString="direction"
            filterName="Direction"
            :selectedOption="filterSelections.direction"
            :options="directionsJson"
            @update-selection="updateSelection"
          />

          <!-- Source Number -->
          <div v-if="activeFilters.includes('sourceNumber')" class="filter-item">
            <el-dropdown
              ref="sourceNumber"
              trigger="click"
              size="small"
              type="primary"
              class="h-full"
              @visible-change="(value) => clearInput('sourceNumberInput', value)"
            >
              <div
                class="px-4 h-full flex items-center font-normal text-sm"
                :style="{
                  'background-color': filterSelections.sourceNumber ? '#CCDDF9' : '#F1F1F1',
                  'border-radius': '.4rem'
                }"
                style="background-color: #F1F1F1; border-radius: .4rem"
              >
                <span class="mr-2" style="color: #5b5b5b">
                  <span v-if="filterSelections.sourceNumber">
                    <span>Source number </span>
                    <strong>is </strong>
                    <span>{{ filterSelections.sourceNumber }}</span>
                  </span>
                  <span v-else>Source number</span>
                </span>
                <i class="icon-novo-arrow-down text-base font-semibold" />
              </div>
              <el-dropdown-menu
                slot="dropdown"
              >
                <div class="px-3 py-2">
                  <el-input
                    v-model="sourceNumberInput"
                    class="w-full"
                    placeholder="Source number"
                  />
                </div>
                <div class="flex justify-between px-3 pt-2 border-t border-grey-lighter">
                  <el-button type="text" size="small" style="color: #CB2233" @click="updateSelection('sourceNumber', '')">
                    Remove
                  </el-button>
                  <el-button type="primary" size="small" :disabled="!sourceNumberInput" @click="updateSelection('sourceNumber', sourceNumberInput)">
                    Done
                  </el-button>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <!-- Source Country -->
          <FilterDropdown
            v-if="activeFilters.includes('sourceCountry')"
            refString="sourceCountry"
            filterName="Source country"
            :selectedOption="filterSelections.sourceCountry"
            :options="countriesJson"
            @update-selection="updateSelection"
          />

          <!-- Destination Number -->
          <div v-if="activeFilters.includes('destinationNumber')" class="filter-item">
            <el-dropdown
              ref="destinationNumber"
              trigger="click"
              size="small"
              type="primary"
              class="h-full"
              @visible-change="(value) => clearInput('destinationNumberInput', value)"
            >
              <div
                class="px-4 h-full flex items-center font-normal text-sm"
                :style="{
                  'background-color': filterSelections.destinationNumber ? '#CCDDF9' : '#F1F1F1',
                  'border-radius': '.4rem'
                }"
                style="background-color: #F1F1F1; border-radius: .4rem"
              >
                <span class="mr-2" style="color: #5b5b5b">
                  <span v-if="filterSelections.destinationNumber">
                    <span>Destination number </span>
                    <strong>is </strong>
                    <span>{{ filterSelections.destinationNumber }}</span>
                  </span>
                  <span v-else>Destination number</span>
                </span>
                <i class="icon-novo-arrow-down text-base font-semibold" />
              </div>
              <el-dropdown-menu
                slot="dropdown"
              >
                <div class="px-3 py-2">
                  <el-input
                    v-model="destinationNumberInput"
                    class="w-full"
                    placeholder="Destination number"
                  />
                </div>
                <div class="flex justify-between px-3 pt-2 border-t border-grey-lighter">
                  <el-button type="text" size="small" style="color: #CB2233" @click="updateSelection('destinationNumber', '')">
                    Remove
                  </el-button>
                  <el-button type="primary" size="small" :disabled="!destinationNumberInput" @click="updateSelection('destinationNumber', destinationNumberInput)">
                    Done
                  </el-button>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <!-- Destination Country -->
          <FilterDropdown
            v-if="activeFilters.includes('destinationCountry')"
            refString="destinationCountry"
            filterName="Destination country"
            :selectedOption="filterSelections.destinationCountry"
            :options="countriesJson"
            @update-selection="updateSelection"
          />

          <!-- Call Status Filter -->
          <FilterDropdown
            v-if="activeFilters.includes('callStatus')"
            refString="callStatus"
            filterName="Call status"
            :selectedOption="filterSelections.callStatus"
            :options="callStatusesJson"
            @update-selection="updateSelection"
          />

          <el-dropdown
            class="flex items-center ml-3"
            trigger="click"
            size="small"
            :hide-on-click="false"
            style="height: 44px"
            @command="toggleFilter"
          >
            <div class="text-blue-dark">
              <i class="icon-novo-plus mr-1" />
              Add filter
            </div>
            <el-dropdown-menu slot="dropdown">
              <div class="px-2 py-1">
                <el-input
                  v-model="searchFilterInput"
                  class="w-full"
                  prefix-icon="icon-novo-search text-xl"
                  placeholder="Search filter"
                />
              </div>
              <div v-if="!filteredOptions.length" class="flex justify-center p-3 text-sm text-grey">
                No matching data
              </div>
              <el-dropdown-item
                v-for="item in filteredOptions"
                :key="item.value"
                :command="item.value"
                :disabled="item.value === 'dateRange'"
                class="flex justify-between items-center"
              >
                {{ item.name }}
                <i
                  v-if="isFilterActive(item.value)"
                  class="icon-novo-check text-lg font-semibold"
                />
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="flex">
          <div class="flex-shrink flex items-end justify-center px-4 py-2 ml-auto">
          <el-button
            type="text"
            style="outline: 0"
            @click.prevent="clearFilters"
          >
            {{ $t('actions.reset') }}
         </el-button>
        </div>
        <div class="flex-shrink flex items-end justify-center px-4 py-2">
          <el-button
            type="primary"
            @click.prevent="applyFilters"
          >
            Apply
         </el-button>
        </div>
      </div>
    </div>
      </div>
  </div>
</template>

<script>
import Moment from 'moment';
import emojiFlags from 'emoji-flags';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';

import callQualityScoresJson from '@/json/callQualityScores.json';
import productTypesJson from '@/json/productTypes.json';
import callStatusesJson from '@/json/callStatuses.json';
import filtersJson from '@/json/filters.json';
import countriesJson from '@/json/countries.json';
import directionsJson from '@/json/direction.json';

import CustomPicker from '../../partials/CustomPicker.vue';
import FilterDropdown from './FilterDropdown.vue';

export default {
  name: 'filter',

  components: {
    CustomPicker,
    FilterDropdown,
  },

  props: {
    filters: {
      default: () => {},
      type: Object,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    subaccountList: {
      default: () => [],
      type: Array,
    },
    defaultDaterange: {
      default: () => [],
      type: Array,
    },
    tab: {
      type: String,
      default: '',
    },
    tabList: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters({
      paginatedLogs: 'logs/getPaginated',
      getFilters: 'logs/getFilters',
      subaccountList: 'common/getsubaccounts',
      filteredLogs: 'logs/getFilteredLogs',
      countries: 'common/getCountries',
    }),

    ...mapState('user', ['timeZone', 'timeZoneOffset']),

    subaccounts() {
      return this.subaccountList.map(s => ({
        id: s.SubAccountUid,
        name: s.SubAccountId,
        value: s.SubAccountId,
      }));
    },

    filterCount() {
      const filters = this.activeFilters.reduce((a, b) => {
        const newObj = { ...a };

        if (Object.keys(this.defaultFilters).some(f => f === b) && b !== 'dateRange') {
          newObj.default[b] = this.defaultFilters[b];
          newObj.touched[b] = this.getFilters[b];
        }

        return newObj;
      }, { default: {}, touched: {} });

      const affectedFilters = Object.keys(filters.default).reduce((a, b) => {
        if (filters.default[b] !== filters.touched[b]) {
          a.push(b);
        }

        return a;
      }, []);

      return affectedFilters.length + 1;
    },
  },

  data: () => {
    return {
      showDatePicker: false,
      filterSelections: {
        subaccount: '',
        productType: '',
        callQualityScore: '',
        sourceCountry: '',
        destinationCountry: '',
        callStatus: '',
        direction: '',
        sourceNumber: '',
        destinationNumber: '',
      },
      sourceNumberInput: '',
      destinationNumberInput: '',

      dates: [],
      dateRange: {},

      searchInput: undefined,
      debounceTimeout: null,

      searchFilterInput: '',
      filteredOptions: filtersJson,

      isMoreFilterVisible: false,
      activeFilters: ['dateRange'],
      defaultFilters: {
        dateRange: [
          Moment().startOf('day').subtract(1, 'w').valueOf(),
          Moment().endOf('day').valueOf(),
        ],
        subaccount: '',
        callQualityScore: '',
        productType: '',
        direction: '',
        sourceNumber: '',
        sourceCountry: '',
        destinationNunber: '',
        destinationCountry: '',
        callStatus: '',
      },

      filtersJson,
      productTypesJson,
      callStatusesJson,
      callQualityScoresJson,
      countriesJson,
      directionsJson,
    };
  },

  created() {
    this.dates = this.defaultFilters.dateRange;
    this.dateRange = { from: this.dates[0], to: this.dates[1] };
  },

  watch: {
    countries(v) {
      this.countriesJson = v.map((c) => {
        const { Country: countryCode, CountryName: countryName } = c;
        const { emoji = '' } = emojiFlags.countryCode(countryCode) || {};
        const name = `${emoji} ${countryName}`;

        return {
          name,
          value: countryName,
        };
      });
    },
    searchFilterInput(v) {
      this.filteredOptions = filtersJson.filter(
        p => p.name.toLowerCase().includes(v.toLowerCase()),
      );
    },
  },

  methods: {
    ...mapMutations({
      setFilters: 'logs/SET_FILTERS',
      setSearch: 'logs/SET_SEARCH',
      setPageNo: 'logs/SET_PAGE_NO',
      setFilteredLogs: 'logs/SET_FILTERED_LOGS',
      resetState: 'logs/RESET_STATE',
    }),

    ...mapActions({
      getLogs: 'logs/fetchLogs',
    }),

    async refresh() {
      this.resetState();

      this.getLogs({
        ...this.getFilters,
        dateOffset: this.timeZoneOffset,
      });
    },

    async applyFilters() {
      this.resetState();

      this.setFilters({
        ...this.filterSelections,
        dateRange: this.dates,
        dateOffset: this.timeZoneOffset,
      });

      await this.getLogs({
        ...this.getFilters,
      });
    },

    isFilterActive(item) {
      return this.activeFilters.some(f => f === item);
    },

    toggleFilter(value) {
      Object.keys(this.filterSelections).forEach((key) => {
        if (key === value) {
          this.filterSelections[key] = '';
        }
      });

      if (this.isFilterActive(value)) {
        this.activeFilters = this.activeFilters.filter(f => f !== value);
      } else {
        this.activeFilters.push(value);
      }
    },

    handleDateVisibility(value) {
      this.showDatePicker = value;
    },

    updateSelection(data, value) {
      this.filterSelections[data] = value;
      this.setFilters({ [data]: value });

      if (data === 'sourceNumber' || data === 'destinationNumber') {
        this.$refs[data].hide();
      }
    },

    clearInput(data, value) {
      if (!value) this[data] = '';
    },

    clearFilters() {
      Object.keys(this.filterSelections).forEach((key) => {
        this.filterSelections[key] = '';
      });
      this.setFilters(this.defaultFilters);
      // this.selectedCountry = '';
      // this.selectedSubaccount = '';
      // this.selectedVolumeSpike = '';
      // this.searchInput = null;

      // this.setSearch('');
      // this.setFilters(this.defaultFilters);
      // this.setFilteredTraffic();
    },

    formatDate(date) { // 01 Jan 2025
      // console.log({ formatDate: Moment(date).format('') });
      return Moment(date).format('DD MMM YYYY');
    },

    dateRangeHandler(d) {
      this.dates = [Moment(d.dateFrom).valueOf(), Moment(d.dateTo).valueOf()];

      console.log({ a: Moment(this.dates[0]).format() });
      console.log({ b: Moment(this.dates[1]).format() });
      console.log({ aNum: this.dates[0] });
      console.log({ bNum: this.dates[1] });
      console.log({ unix: Moment(this.dates[0]).unix() });
      console.log({ unix: Moment(this.dates[0]).utcOffset(0, true).unix() });
      // console.log({ c: Number(Moment(d.dateFrom).startOf('day')) });
      // console.log({ d: Number(Moment(d.dateTo).endOf('day')) });

      // this.$emit('update-filter', {
      //   daterange: this.dates,
      //   tz: d.tz,
      // });
    },

    searchTraffic() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.setSearch(this.searchInput);
        this.setPageNo(1);
        this.setFilteredLogs();
      }, 300);
    },

    // updateFilter(v) {
    //   this.$emit('update-filter', v);
    // },

    // applyFilter(v) {
    //   this.setFilters({
    //     ...this.filters,
    //     pageOffset: 0,
    //   });

    //   this.setPagination({
    //     pageInfo: {
    //       index: 1,
    //       end: false,
    //     },
    //   });

    //   this.$emit('apply-filter', v);
    // },
  },
};
</script>

<style scoped lang="scss">
.vo-datepicker {
  width: 100% !important;
}

.filter-item {
  padding: 0.25rem;
  height: 44px;
}

.filter-options {
  max-height: 250px;
  overflow-y: auto;
}

.el-dropdown-menu--small .el-dropdown-menu__item {
  padding: 0.5rem 1rem;
}


// .custom-dropdown {
//   position: relative;
// }

// .options-list {
//   max-height: 250px;
//   overflow-y: auto;
//   padding-bottom: 40px;
// }

// .footer {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   padding-top: .2rem;
// }
</style>
