/* eslint-disable no-param-reassign */
import Moment from 'moment';
import actions from './actions';

export default {
  namespaced: true,

  state: {
    filters: {
      subAccountId: 'all',
      daterange: [],
      type: 'all',
      direction: '',
      status: 'all',
      search: '',
      limit: 15,
      offset: 0
    },
    subAccounts: [],
    countries: [],
    cdrFilters: {
      subAccountId: 'all',
      daterange: [],
      productType: 'all',
      direction: 'all',
      status: 'all',
      search: '',
      limit: 15,
      pageOffset: 0,
      userCountry: 'All',
      platformCountry: 'All',
      sessionId: 'All',
      sipStatus: 'All',
      callQuality: 'all'
    }
  },

  getters: {
    getfilters: st => st.filters,
    getCdrfilters: st => st.cdrFilters,
    getsubaccounts: st => st.subAccounts,
    getCountries: st => st.countries,
  },

  mutations: {
    SET_FILTERS: (st, filters) => { st.filters = filters; },
    SET_CDR_FILTERS: (st, filters) => { st.cdrFilters = filters; },
    SET_SUBACCOUNTS: (st, payload) => { st.subAccounts = payload.subAccounts; },
    SET_COUNTRIES: (st, payload) => { st.countries = payload.countries; },
  },

  actions,
};
