import Vue from 'vue';
import moment from 'moment';
import actions from './actions';

export default {
  namespaced: true,

  state: {
    logs: [],
    filteredLogs: [],

    loading: true,
    search: '',
    pageNo: 1,
    pageSize: 5,
    paginatedLogs: [],
    limit: 10000,

    filters: {
      dateRange: [
        moment()
          .startOf('day')
          .subtract(1, 'w')
          .valueOf(),
        moment()
          .endOf('day')
          .valueOf(),
      ],
      subaccount: '',
      callQualityScore: '',
      productType: '',
      direction: '',
      sourceNumber: '',
      sourceCountry: '',
      destinationNumber: '',
      destinationCountry: '',
      callStatus: '',
    },
  },

  mutations: {
    SET_FILTERED_LOGS(state) {
      let filteredLogs = state.logs;

      if (state.search) {
        const filters = ['SubAccountId', 'Product'];
        filteredLogs = filteredLogs.filter(row => filters.some((k) => {
          const s = row[k];

          if (s) {
            return s.toLowerCase().includes(state.search.toLowerCase());
          }
          return 0;
        }));
      }

      const { pageNo, pageSize } = state;
      const start = pageNo * pageSize - pageSize;
      const end = start + pageSize;

      Vue.set(state, 'filteredLogs', filteredLogs);
      Vue.set(state, 'paginatedLogs', filteredLogs ? filteredLogs.slice(start, end) : []);
    },
    SET_LOGS: (st, payload) => {
      Vue.set(st, 'logs', payload.logs);
    },
    SET_LOADING(state, payload) {
      Vue.set(state, 'loading', payload);
    },
    SET_PAGE_NO(state, payload) {
      Vue.set(state, 'pageNo', payload);
    },
    SET_PAGE_SIZE(state, payload) {
      Vue.set(state, 'pageSize', payload);
    },
    SET_FILTERS(state, payload) {
      Vue.set(state, 'filters', { ...state.filters, ...payload });
    },
    SET_SEARCH(state, payload) {
      Vue.set(state, 'search', payload);
    },
    RESET_STATE(state) {
      Vue.set(state, 'pageNo', 1);
      Vue.set(state, 'search', '');
      Vue.set(state, 'filteredLogs', []);
      Vue.set(state, 'paginatedLogs', []);
    },
  },

  getters: {
    getLogs: st => st.logs,
    getFilters: state => state.filters,
    getLoading: state => state.loading,
    getPaginated: state => state.paginatedLogs,
    getPageNo: state => state.pageNo,
    getPageSize: state => state.pageSize,
    getSearch: state => state.search,
    getFilteredLogs: state => state.filteredLogs,
  },

  actions,
};
