import http from '../../../utils/http';

export default {
  // Fetch Subaccounts
  // eslint-disable-next-line arrow-body-style
  fetchSubAccounts: async ({ commit }) => {
    try {
      const { data } = await http.get('/voice/sub-accounts/details');
      commit({ type: 'SET_SUBACCOUNTS', subAccounts: data });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  getCountriesByAccount: async ({ commit }) => {
    const { AccountId: accountId } = JSON.parse(localStorage.getItem('CPV3_User') || {});

    try {
      const { data } = await http.get(`/accounts/${accountId}/countries`);
      commit({ type: 'SET_COUNTRIES', countries: data });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
