<template>
  <ul class="tab list-reset">
    <li
      v-for="tab in tabList"
      :key="tab.value"
      :class="[tab.value === value && 'tab__item--active border-red border-b-2']"
      class="tab__item py-3 text-base text-grey-dark hover:text-blue mr-5"
      @click="$emit('update-tab', tab.value)"
    >
      <!-- {{ tab.name }} -->

      {{ $t(`voice.tabs.${tab.index}`)}}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Tabs',

  props: {
    value: {
      type: String,
      default: '',
    },

    tabList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  position: relative;
  display: flex;

  &__item {
    cursor: pointer;

    &--active {
      color: black;
    }
  }
}
</style>
