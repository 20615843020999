<template>
  <div class="dashboard py-16 mx-10">
    <section class="card bg-white p-16 shadow relative block rounded overflow-hidden">
      <h1 class="flex items-center mb-12">
        <img
          src="../assets/voice.svg"
          alt="Voice"
          class="header-logo mr-2">
          <p class="text-2xl text-blue">
            {{ $t('sidebar_menu_parent.Voice') }}
          </p>
      </h1>
      <div class="card__inner">
        <h2 class="text-black text-4xl">
          {{ $t('voice.default[0]') }}
        </h2>
        <p class="pt-8 pr-10 text-black text-base">
            {{ $t('voice.default[1]') }}
        </p>
        <ul class="list list-reset mt-10 text-sm text-black">
          <li>  {{ $t('voice.default[2]') }}</li>
          <li>  {{ $t('voice.default[3]') }} </li>
        </ul>
        <p class="text-base mt-10">
            {{ $t('voice.default[4]') }}
          <a
            class="text-blue no-underline hover:underline"
            href="https://wavecell.com/voice/"
            target="_blank">
             {{ $t('voice.default[5]') }}
          </a>
        </p>
      </div>
      <div class="pt-20">
        <a
          class="no-underline text-white font-normal"
          href="mailto:cpaas-sales@8x8.com?subject=I'm interested in Voice!
          &body=Hi 8x8, I'd like to learn more about integrating Voice into my business.">
          <el-button
            type="primary"
            icon="el-icon-s-promotion"
            size="medium"
          >
                {{ $t('voice.default[6]') }}
          </el-button>
        </a>
      </div>
      <img src="../assets/dashboard-illustration.svg" alt="" class="card__voice-illustration">
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'home',
};
</script>

<style lang="scss" scoped>
.card {
  margin: 0 auto;
  max-width: 1000px;

  &__title {
    height: 70px;
  }

  &__inner {
    padding-right: 25em;
  }

  &__voice-illustration {
    position: absolute;
    bottom: 0;
    right: 50px;
  }
}

.list {
  & li {
    padding-left: 10px;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &:before {
      content: '';
      width: 5px;
      height: 5px;
      background: var(--red);
      position: absolute;
      left: -5px;
      top: 6px;
      border-radius: 50%;
    }
  }
}

.header-logo {
  width: 48px;
}
</style>
