<template>
  <div>
    <div class="mb-20 flex flex-col">
      <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5 mb-5 mt-5">{{ $t('sidebar_menu_children.Reports')}}</h1>
      <div class="flex -mx-3">
        <div class="px-3 w-full">
          <span class="text-sm text-grey mb-5 block">
            {{ $t('voice.reports.index[0]')}}
          </span>
          <Tabs
            :value="filters.type"
            :tabList="tabsJson"
            @update-tab="v => updateFilter({ type: v })"
            class="mb-5"
          />
          <Summary
            v-loading="loading"
            :tab="tab"
            :tabList="tabsJson"
            :chartData="chartData"
            :summary="summary"
            :filters="filters"
            class="mb-5"
          />
          <Table
            v-loading="loading"
            :data="tableData"
            :filters="filters"
          />
        </div>
        <div class="main__aside px-3">
          <div class="pt-20 -mt-20 sticky pin-t">
            <Filters
              :filters="filters"
              :loading="loading"
              :subaccountList="subaccountList"
              :defaultDaterange="defaultDaterange"
              @update-filter="updateFilter"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment';
import {
  mapActions,
  mapMutations,
  mapGetters,
  mapState,
} from 'vuex';
import Tabs from '@/views/partials/Tabs.vue';
import tabsJson from '@/json/tabs.json';
import Summary from './partials/Summary.vue';
import Table from './partials/Table.vue';
import Filters from './partials/Filters.vue';

export default {
  name: 'Reports',

  components: {
    Filters,
    Summary,
    Table,
    Tabs,
  },

  data() {
    const defaultDaterange = [
      Number(Moment().startOf('day').subtract(1, 'w')),
      Number(Moment().endOf('day')),
    ];

    return {
      tabsJson,
      defaultDaterange,
      activeName: 'calls-report',
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      hasVoiceMessaging: 'user/getVoiceMessagingAccess',
      filters: 'common/getfilters',
      subaccountList: 'common/getsubaccounts',
      summary: 'report/getSummary',
      tableData: 'report/getTableData',
      chartData: 'report/getChartData',
    }),

    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  created() {
    this.fetchSubaccounts();
    this.getUser();
  },

  methods: {
    ...mapActions({
      getSubAccounts: 'common/fetchSubAccounts',
      getReports: 'report/fetchReports',
      getUser: 'user/fetchUser',
    }),

    ...mapMutations({
      setFilters: 'common/SET_FILTERS',
    }),

    updateFilter(props) {
      this.setFilters({
        ...this.filters,
        ...props,
        offset: this.timeZoneOffset,
      });

      // Fetch data
      this.fetchData();
    },

    async fetchData() {
      this.loading = true;
      try {
        await this.getReports({
          subAccountId: this.filters.subAccountId,
          daterange: this.filters.daterange,
          type: this.filters.type,
          offset: this.timeZoneOffset,
        });

        const { track } = this.$telemetry;
        track('report_viewed', {
          channel: 'Voice',
          daterange: this.filters.daterange,
          filterApplied: this.filters,
          uiArea: 'reports',
        });

      } catch (err) {
        this.$showError(this, err);
        // this.showMessage(err.message, 'error');
      } finally {
        this.loading = false;
      }
    },

    async fetchSubaccounts() {
      this.loading = true;
      try {
        await this.getSubAccounts();

        // Set default value of subaccount
        if (this.subaccountList.length) {
          this.updateFilter({
            daterange: this.filters.daterange.length
              ? this.filters.daterange
              : this.defaultDaterange,
            subAccountId: this.filters.subAccountId || this.subaccountList[0].SubAccountUid,
            type: this.filters.type || this.tabsJson[0].value,
            offset: this.timeZoneOffset,
          });
        }
      } catch (err) {
        // this.showMessage(err.message, 'error');
        this.$showError(this, err);
      } finally {
        this.loading = false;
      }
    },

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },
  },
};
</script>

<style lang="scss">
// Add this override to hide border in tabs for this page
.reports-tab .el-tabs__nav-wrap::after {
  height: 0px;
  background: none;
}
</style>
