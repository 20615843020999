<template>
  <div class="my-2 mx-3 text-right">
    <div class="bg-white flex justify-between items-center text-xs leading-none">
      <div class="text-base">
        <span> {{ $t("app_labels.rows_per_page") }}:</span>
        <el-select
          v-model="pageSize"
          class="ml-3"
          style="width: 65px"
          @change="handleSizeChange"
        >
          <el-option
            v-for="page in [5,10,20,50]"
            :key="page"
            :label="page"
            :value="page"
          />
        </el-select>
      </div>
      <div class="flex items-end">
        <a
          class="p-3 inline-block leading-none cursor-pointer text-base"
          :class="{ 'cursor-not-allowed text-grey disabled pointer-events-none': loading || pageNo === 1}"
          @click.prevent="handlePaginationChange('prev')"
        >
          <i class="icon-novo-arrow-left-long mr-2" /> <span class="capitalize">{{ $t('actions.prev') }}</span>
        </a>
        <div class="text-base mr-3 ml-3">
          <el-select
            v-model="pageNo"
            style="width: 65px"
            @change="handleSelectPage"
          >
            <el-option
              v-for="page in lastPage"
              :key="page"
              :label="page"
              :value="page"
            />
          </el-select>
          {{ $t("app_labels.of_item_pages", { item: lastPage.toLocaleString() }) }}
        </div>
        <a
          class="p-3 inline-block leading-none cursor-pointer text-base capitalize"
          :class="{ 'cursor-not-allowed text-grey disabled pointer-events-none': loading || pageNo >= lastPage }"
          @click.prevent="handlePaginationChange('next')"
        >
          <span class="capitalize">{{ $t('actions.next') }}</span> <i class="ml-2 icon-novo-arrow-right-long" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Pagination',

  computed: {
    ...mapGetters({
      pageNo: 'logs/getPageNo',
      pageSize: 'logs/getPageSize',
      loading: 'logs/getLoading',
      filteredLogs: 'logs/getFilteredLogs',
    }),

    total() {
      if (this.filteredLogs && this.filteredLogs.length) {
        return this.filteredLogs.length;
      }
      return 0;
    },

    lastPage() {
      return Math.ceil(this.total / parseInt(this.pageSize, 10));
    },
  },

  methods: {
    ...mapMutations({
      setPageNo: 'logs/SET_PAGE_NO',
      setPageSize: 'logs/SET_PAGE_SIZE',
      setFilteredLogs: 'logs/SET_FILTERED_LOGS',
    }),

    handleSizeChange(size) {
      this.setPageNo(1);
      this.setPageSize(+size);
      this.setFilteredLogs();
    },

    handleSelectPage(pn) {
      this.setPageNo(+pn);
      this.setFilteredLogs();
    },

    handlePaginationChange(action) {
      let page = this.pageNo;
      if (action === 'next') {
        page += 1;
      }

      if (action === 'prev' && page) {
        page -= 1;
      }

      this.setPageNo(+page);
      this.setFilteredLogs();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
