import { parsePhoneNumber } from 'awesome-phonenumber';
import countries from '@/json/countries.json';

export default {
  methods: {
    // Format phone number
    formatPhone(number) {
      const res = number.match(/^[+][0-9]/) ? number : `+${number}`;
      const pn = parsePhoneNumber(res);
      return pn.number.international;
    },

    // Get Region
    getPhoneRegion(number, fullname = false) {
      const res = number.match(/^[+][0-9]/) ? number : `+${number}`;
      const countryCode = parsePhoneNumber(res).regionCode;
      return fullname
        ? countries.filter(country => country.code === countryCode)[0].name
        : countryCode.toLowerCase();
    },

    // Get Region Name
    getRegionName(countryCode) {
      return countries.filter(country => country.code === countryCode)[0].name;
    },
  },
};
