import moment from 'moment';
import momentDuration from 'moment-duration-format';
import { parsePhoneNumber } from 'awesome-phonenumber';

momentDuration(moment);

const fn = {
  /**
    * Return formated price
    * @param {Number} n -> number/value
    * @param {Number} d -> max decimal places
    * @param {String} l -> locale
    * @return {String} formatted price
  * */
  formatPrice(n, d = 7, l = 'en-US') {
    if (Number.isNaN(n)) return '0.00';

    const num = Number(n);
    const maximumFractionDigits = d;

    let ns = num.toLocaleString(l, { maximumFractionDigits });

    if (ns.split('.').length < 2) {
      ns = `${ns}.00`;
    }

    return ns;
  },

  timeFromCalendar(datetime) {
    return moment(datetime).calendar(null, {
      sameDay: 'h:mm A',
      lastDay: '[Yest] h A',
      nextDay: '[Tom] h A',
      lastWeek: '[Last] ddd, h A',
      nextWeek: 'dd MMM',
      sameElse() {
        return this.year() === moment().year() ? 'D MMM' : 'D MMM YYYY';
      },
    });
  },

  // e.g. 03:59 PM
  formatTime(time) {
    return moment.utc(time).format('hh:mm A');
  },

  // e.g. 03:48:50 PM
  formatTimeWithSeconds(time) {
    return moment.utc(time).format('hh:mm:ss A');
  },


  // e.g. 12 Jun 2019 03:59 PM
  formatDateTime(datetime) {
    return moment.utc(datetime).format('D MMM YYYY hh:mm A');
  },

  formatDateTime2(datetime) {
    return moment.utc(datetime).format('MMM DD YYYY hh:mm A');
  },

  // e.g. 12 Jun 2019
  formatDate(date) {
    return moment.utc(date).format('D MMM YYYY');
  },

  formatDate2(date) {
    return moment.utc(date).format('MMM DD YYYY');
  },

  formatTz(tz) {
    const offset = new Date(tz.GMTOffset * 1000).toUTCString().split(' ')[4].replace(/:00$/, '');
    let prefix = '-';
    if (tz.GMTOffset > 0) {
      prefix = '+';
    }
    return `(${tz.TimeZoneName} GMT${prefix}${offset})`; // eslint-disable-line
  },

  // e.g 1000 to '1,000'
  formatNumber(n) {
    return n.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  },

  // e.g 'in 15 days', '1 hr ago' or '1 min ago'
  formatDateFromNow(datetime) {
    return moment.utc(datetime).fromNow();
  },

  // e.g '60' to '1:00'
  formatDurationSeconds(s) {
    const hour = s < 39600 && s >= 3600 ? 'hr' : 'hrs';
    const min = s < 3600 && s >= 60 ? 'min' : 'mins';
    const sec = s < 60 && s >= 1 ? 'sec' : 'secs';

    const unit = { h: hour, m: min, s: sec };
    return moment.duration(s, 'second').format(`h [${unit.h}] m [${unit.m}] s [${unit.s}]`);
  },

  // e.g. '46707123456' to '+46 70 712 34 56'
  formatPhoneNumber(n) {
    const s = n.match(/^[+][0-9]/) ? n : `+${n}`;
    const pn = parsePhoneNumber(s);
    return pn.number.international || n;
  },
};

const Wc = {
  install(Vue) {
    Vue.mixin({
      created() {
        // Global wc instance
        this.$vc = fn;
      },
    });

    Vue.prototype.$vc = fn; // eslint-disable-line
  },
};

export default Wc;
