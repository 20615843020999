<template>
  <div class="relative">
    <div class="flex p-4 pb-8 -mb-4 rounded-t bg-grey-lightest">
      <div class="w-full">
        <span class="text-xs block mb-2">{{ $t('column_labels.date_range')}} {{ timeZone }}</span>
        <el-date-picker
          v-model="dates"
          type="daterange"
          format="dd MMM yyyy"
          unlink-panels
          range-separator="—"
          :start-placeholder="$t('date_labels.start_date')"
          :end-placeholder="$t('date_labels.end_date')"
          size="small"
          class="vo-datepicker"
          :picker-options="pickerOptions"
          @change="v => updateFilter({ daterange: v })"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="flex flex-col rounded p-4 w-full justify-between text-black bg-grey-lighter">
      <h3 class="font-medium text-black text-sm mb-5">
        {{ $t('app_labels.filters')}}
      </h3>
      <div>
        <span class="text-xs block mb-2">{{ $t('column_labels.subaccount', 2)}}</span>
        <el-select
          :value="filters.subAccountId"
          :disabled="loading"
          class="w-full"
          size="small"
          filterable
          @change="v => updateFilter({ subAccountId: v })"
          >
          <el-option
            v-for="subaccount in [{SubAccountUid: 'all', SubAccountId: $t('column_labels.all')}, ...subaccountList]"
            :key="subaccount.SubAccountUid"
            :label="subaccount.SubAccountId"
            :value="subaccount.SubAccountUid"
            >
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: 'filter',

  props: {
    filters: {
      default: () => {},
      type: Object,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    subaccountList: {
      default: () => [],
      type: Array,
    },
    defaultDaterange: {
      default: () => [],
      type: Array,
    },
    tab: {
      type: String,
      default: '',
    },
    tabList: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  data() {
    const self = this;
    return {
      dates: [],

      pickerOptions: {
        disabledDate(time) {
          return Moment() < time || Moment().diff(time, 'months') >= 6;
        },
        shortcuts: [
          {
            text: self.$t('date_labels.today'),
            onClick(picker) {
              const end = Moment().endOf('day');
              const start = Moment().startOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: self.$t('date_labels.last_7_days'),
            onClick(picker) {
              const start = Moment().subtract(1, 'week').startOf('day');
              const end = Moment().endOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: self.$t('date_labels.last_14_days'),
            onClick(picker) {
              const start = Moment().subtract(2, 'week').startOf('day');
              const end = Moment().endOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: self.$t('date_labels.last_30_days'),
            onClick(picker) {
              const start = Moment().subtract(30, 'days').startOf('day');
              const end = Moment().endOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },

  created() {
    this.dates = this.filters.daterange.length
      ? this.filters.daterange
      : this.defaultDaterange;
  },

  methods: {
    updateFilter(v) {
      this.$emit('update-filter', v);
    },
  },
};
</script>

<style scoped lang="scss">
.vo-datepicker {
  width: 100% !important;
}
</style>
