<template>
  <span>
    <p
      v-if="title"
      class="mb1 mgb">
      <strong>{{ title }}</strong>
    </p>


    <el-date-picker
      ref="datePicker"
      v-model="date.range"
      type="datetimerange"
      format="MM/dd/yyyy hh:mm A"
      size="medium"
      align="left"
      class="mb2"
      :default-time="['00:00:00', '23:59:59']"
      :clearable="false"
      :editable="false"
      :picker-options="pickerOptions"
      :placeholder="$t('date_labels.pick_range')"
      @change="handleChange"
    />

    <el-select
      v-if="showTz"
      v-model="date.tz"
      filterable
      size="small"
      class="sel-tz"
      :placeholder="$t('date_labels.time_zone')"
      @change="handleChange">
      <el-option
        v-for="tz in timezones"
        :key="tz.TimeZoneId"
        :label="tz.Combined"
        :value="tz.TimeZoneName" />
    </el-select>
  </span>
</template>

<script>
import moment from 'moment-timezone';

export default {
  name: 'CustomPicker',

  props: {
    title: {
      type: String,
      default: '',
    },

    showTz: {
      type: Boolean,
      default: true,
    },

    dateRange: {
      type: Object,
      default() {
        return {};
      },
    },

    showPicker: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const from = moment().startOf('day').subtract(1, 'w').format();
    const to = moment().endOf('day').format();
    const hasDateFilter = Object.keys(this.dateRange).length;
    console.log({ datePicker: moment(this.dateRange.from).format() });
    console.log({ datePicker: moment(this.dateRange.to).format() });

    return {
      date: {
        range: hasDateFilter ? [this.dateRange.from, this.dateRange.to] : [from, to],
      },
    };
  },

  computed: {
    pickerOptions() {
      const end = moment().endOf('day').format();
      return {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() < moment().subtract(185, 'days') || time.getTime() > moment();
        },
        shortcuts: [
          {
            text: 'Today',
            onClick(picker) {
              const start = moment().startOf('day').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last hour',
            onClick(picker) {
              const start = moment().subtract(1, 'hour').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Yesterday',
            onClick(picker) {
              const start = moment().subtract(1, 'day').startOf('day').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 7 days',
            onClick(picker) {
              const start = moment().subtract(7, 'days').startOf('day').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This month',
            onClick(picker) {
              const start = moment().startOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const start = moment().subtract(1, 'month').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const start = moment().subtract(3, 'months').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 6 months',
            onClick(picker) {
              const start = moment().subtract(6, 'months').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      };
    },
  },

  created() {
    this.fetchData();
  },

  watch: {
    showPicker(val) {
      if (val) {
        this.$refs.datePicker.showPicker();
      }
    },
  },

  methods: {
    fetchData() {
    },

    handleChange() {
      const { range, tz } = this.date || {};
      let dateFrom = moment().subtract(1, 'week').startOf('day');
      let dateTo = moment().endOf('day');
      console.log({ range, tz });

      if (range && range.length) {
        [dateFrom, dateTo] = range;
      }

      console.log({ dateFrom, dateTo });
      this.$emit('change', { dateFrom, dateTo, tz });
    },
  },
};
</script>
<style lang="scss">
  .sel-tz {
    width: 220px;
  }
  .mgb {
    margin-bottom: .5em;
    font-size: 13px;
    color: #888686;
  }

  .el-date-range-picker__time-header {
    display: flex !important;
    justify-content: space-between !important;
    padding: 12px 15px !important;

    .el-date-range-picker__editors-wrap {
      display: inline-table;

      &:first-of-type {
        &::before {
          content: 'From';
          font-size: 14px;
          margin-right: 5px;
        }
      }

      &:nth-child(3) {
        &::before {
          content: 'To';
          font-size: 14px;
          margin-right: 5px;
        }
      }

      .el-date-range-picker__editor {
        .el-input__inner {
          width: 105px;
        }
      }
    }

    .el-icon-arrow-right {
      display: none !important;
    }
  }

  .el-picker-panel__footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px !important;

    .el-button {
      &:first-child {
        color: #CB2233;

        span {
          display: none;
        }

        &::before {
          content: 'Reset';
          display: inline-block;
        }
      }

      &:last-child {
        span {
          display: none;
        }

        &::before {
          content: 'Apply';
          display: inline-block;
        }
      }
    }
  }

  .el-picker-panel__shortcut {
    height: 40px;
  }
</style>
