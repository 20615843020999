<template>
  <LineChart
    :chart-data="getGraphData()"
    :options="chartOption"
    class="w-full h-full absolute pin-t pin-l"
  />
</template>

<script>
import Moment from 'moment';
import { mapState } from 'vuex';
import LineChart from '@/views/partials/LineChart.vue';

export default {
  name: 'ReportChart',

  components: {
    LineChart,
  },

  props: {
    chartData: {
      default: () => [],
      type: Array,
    },
    filters: {
      default: () => {},
      type: Object,
    },
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  data() {
    return {
      chartOption: {
        legend: false,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                color: '#ECECEC',
              },
              ticks: {
                min: 0,
                autoskip: true,
                autoSkipPadding: 10,
              },
              stacked: false,
              display: false,
              beginAtZero: true,
              scaleLabel: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                color: '#ECECEC',
              },
              ticks: {
                suggestedMax: 5,
                beginAtZero: true,
                precision: 0,
                callback(value) {
                  return value >= 1000 ? `${(value / 1000).toFixed((value / 1000) % 1 ? 1 : 0)}K` : value;
                },
              },
            },
          ],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
      },
    };
  },

  methods: {
    formatThousand(n) {
      return n >= 1000 ? (n / 1000).toFixed(n % 1 ? 2 : 0) : n;
    },

    isSameHour(d1, d2) {
      return (
        Moment(d1)
          .utc()
          .unix()
        === Moment(d2)
          .utc()
          .unix()
      );
    },

    mergeHourValue(days, dataset) {
      return days.map((date) => {
        const total = dataset
          .filter(d => this.isSameHour(date, d.date))
          .reduce((acc, curr) => acc + curr.value || 0, 0);
        return total;
      });
    },

    generateDays(from, to, format = '') {
      const range = [];
      do {
        range.push(
          from
            .clone()
            .startOf('day')
            .format(format),
        );
      } while (from.add(1, 'days').diff(to) < 0);
      return range;
    },

    generateHours(from, to, format = '') {
      const range = [];
      do {
        range.push(
          from
            .clone()
            .startOf('hour')
            .format(format),
        );
      } while (from.add(1, 'hours').diff(to) < 0);
      return range;
    },

    anchorDays(dayOffset) {
      let from = null;
      let to = null;
      let format = 'MMM D';

      switch (dayOffset) {
        // Today
        case 1:
          from = Moment().startOf('day');
          to = Moment().endOf('day');
          format = 'HH:mm';
          break;
        // 7 days
        case 7:
          from = Moment()
            .subtract(1, 'week')
            .startOf('day');
          to = Moment().endOf('day');
          format = 'MMM D';
          break;
        // 14 days
        case 14:
          from = Moment()
            .subtract(2, 'week')
            .startOf('day');
          to = Moment().endOf('day');
          break;
        // 30 days
        case 30:
          from = Moment()
            .subtract(30, 'days')
            .startOf('day');
          to = Moment().endOf('day');
          break;
        default:
          break;
      }
      return { from, to, format };
    },

    dateRange() {
      const start = this.filters.daterange[0];
      const end = this.filters.daterange[1];

      const isDay = Moment(start).isSame(Moment(end), 'days');
      const days = isDay
        ? this.generateHours(Moment(start).startOf('day'), Moment(end).endOf('day'), 'MMMM DD, YYYY HH:ss (UTC)')
        : this.generateDays(Moment(start).startOf('day'), Moment(end).endOf('day'), 'MMMM DD, YYYY (UTC)');
      return days;
    },

    populateData() {
      const cd = this.chartData;

      const start = this.filters.daterange[0];
      const end = this.filters.daterange[1];

      const isDay = Moment(start).isSame(Moment(end), 'days');
      const days = isDay
        ? this.generateHours(Moment(start).startOf('day'), Moment(end).endOf('day'))
        : this.generateDays(Moment(start).startOf('day'), Moment(end).endOf('day'));

      return Object.keys(cd).map((code) => {
        if (this.filters.type.match(/vm/g) && code.match(/Incoming/g)) {
          return {};
        }

        // Fill-in missing dates
        let d = [];

        if (isDay) { // If hourly
          d = this.mergeHourValue(days, cd[code].data);
        } else { // If daily
          days.forEach((day) => {
            // Check if day has data
            const dayData = cd[code].data.find(
              dd => Moment(dd.date).isSame(Moment(day), 'day'),
            );

            d.push(dayData && dayData.value ? dayData.value : 0);
          });
        }

        return {
          label: code,
          data: d,
          borderWidth: 0,
          radius: 0,
          pointHoverRadius: 3,
          borderColor: this.getLabelColor(code),
          backgroundColor: this.getLabelColor(code),
          pointBackgroundColor: this.getLabelColor(code),
          pointBorderColor: '#FFF',
          spanGaps: true,
          lineTension: 0,
        };
      });
    },

    getLabelColor(code) {
      let rgba = 'rgba(75, 37, 143, 0.8)';
      switch (code) {
        case 'Incoming':
          rgba = 'rgba(0, 60, 90, 0.8)';
          break;
        case 'Outgoing':
          rgba = 'rgba(31, 168, 217, 0.8)';
          // Outgoing
          break;
        default:
          break;
      }
      return rgba;
    },

    dateRangeWithTz() {
      const dr = this.dateRange();
      
      return (dr.map(d => `${d} ${this.timeZone}`)); 
    },

    getGraphData() {
      return {
        labels: this.dateRangeWithTz(),
        datasets: this.populateData().reverse(),
      };
    },

    toDecimalString(n) {
      return this.decimalStringFormat(n);
    },
  },
};
</script>

<style lang="scss">
.legend {
  height: 8px;
  width: 8px;
  display: inline-block;
}

.chart-container {
  width: 100%;
  flex-grow: 0;
  height: 200px;
  position: relative;

  &--large {
    height: 300px;
  }

  &--small {
    height: 130px;
  }
}
</style>
