import actions from './actions';

export default {
  namespaced: true,

  state: {
    user: {},
    hasVoiceMessaging: false,

    timeZoneName: null,
    timeZoneOffset: 0,
    timeZone: '(UTC-00)',
    isTimeZoneAllowed: false,
    shouldHideCost: false,
  },

  getters: {
    getUser: st => st.user,
    getVoiceMessagingAccess: st => st.hasVoiceMessaging,
    getHideCostFlag: st => st.shouldHideCost,
  },

  mutations: {
    // eslint-disable-next-line no-param-reassign
    SET_USER: (st, payload) => {
      // eslint-disable-next-line no-param-reassign
      st.user = payload.user;

      const {
        AccountConfig: config,
        BetaFeatures,
        TimeZoneName,
        TimeZoneGMTOffset,
      } = payload.user;

      const accountConfig = JSON.parse(config || '{}');
      st.shouldHideCost = accountConfig.hide_cost;

      // BetaFeatures restriction
      if (BetaFeatures && BetaFeatures.length) {
        // eslint-disable-next-line no-param-reassign
        st.hasVoiceMessaging = BetaFeatures.includes('VO-Messaging');
      }

      st.isTimeZoneAllowed = true;

      // User timezone settings
      st.timeZoneName = TimeZoneName || 'UTC / Greenwich Mean Time'; // eslint-disable-next-line
      st.timeZoneOffset = TimeZoneGMTOffset ? (TimeZoneGMTOffset / 60) : 0; // offset should be in minutes
      let { timeZone } = st;
      if (TimeZoneGMTOffset) {
        const offset = new Date(TimeZoneGMTOffset * 1000).toUTCString().split(' ')[4].replace(/:00$/, '');
        let prefix = '-';
        if (TimeZoneGMTOffset > 0) {
          prefix = '+';
        }
        timeZone = `(UTC${prefix}${offset})`;
      }
      st.timeZone = timeZone;
    },
  },

  actions,
};
