
export default {
  // Fetch Subaccounts
  // eslint-disable-next-line arrow-body-style
  fetchUser: ({ commit }) => {
    const user = localStorage.getItem('CPV3_User') || {};

    try {
      const userData = JSON.parse(user);
      commit({ type: 'SET_USER', user: userData });

      return userData;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
