import moment from 'moment';
import http from '../../../utils/http';
import countriesJson from '../../../json/countries.json';

const convertDirection = (direction) => {
  if (direction === 'Inbound') return 0;
  if (direction === 'Outbound') return 1;
  return '';
};

const getCountryCode = (countryName) => {
  const { code = '' } = countriesJson.find(c => c.name === countryName) || {};
  return code;
};

export default {
  // Fetch Subaccounts
  // eslint-disable-next-line arrow-body-style
  fetchLogs: async ({ commit, state }, payload = {}) => {
    const { limit } = state;
    try {
      commit('SET_LOADING', true);
      const {
        dateRange, subaccount, direction, callStatus, sourceCountry, destinationCountry, ...rest
      } = payload;
      const params = {
        ...rest,
        limit,
        subAccountId: subaccount,
        daterange: payload.dateRange.map(dt => moment(dt).unix()),
        direction: convertDirection(direction),
        sourceCountry: getCountryCode(sourceCountry),
        destinationCountry: getCountryCode(destinationCountry),
        callStatus: callStatus.replace(/ /g, '_'),
      };

      const user = localStorage.getItem('CPV3_User') || {};
      const accountId = JSON.parse(user).AccountId;
      const queryString = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
      const { data } = await http.get(`/accounts/${accountId}/voice-logs?${queryString}`);

      commit({ type: 'SET_LOGS', logs: data.data });
      commit('SET_FILTERED_LOGS');
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  exportVoiceLogs: async ({ commit }, payload) => {
    try {
      commit('SET_LOADING', true);
      const result = await http.post('/reports/voice/logs/export', payload);

      return 1;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
