/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    reports: [],
  },

  getters: {
    getTableData: st => st.reports.grid,
    getSummary: st => st.reports.total,
    getChartData: st => st.reports.chart,
  },

  mutations: {
    FETCH_REPORTS: (st, payload) => { st.reports = payload.reports; },
  },

  actions,
};
