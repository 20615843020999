<template>
  <div class="flex w-full text-xs">
    <div class="w-full">
      <div class="mt-5 block lg:flex">
        <!-- Chart -->
        <div class="relative w-full lg:w-2/3 lg:mr-5">
          <div class="chart w-full relative">
            <LineChart
              :chart-data="chartData"
              :options="chartOption"
              :filters="filters"
              class="w-full h-full absolute pin-t pin-l"
              />
          </div>
          <div class="flex justify-between text-xs mt-2">
            <span>{{ formatDateLabel(filters.daterange[0], true) }}</span>
            <span>{{ formatDateLabel(filters.daterange[1]) }}</span>
          </div>
        </div>

        <!-- Summary -->
        <div class="w-full lg:w-1/3">
          <ul class="list-reset">
            <!-- Inbound -->
            <li
              v-if="!filters.type.match(/vm/i)"
              class="pb-5 my-5 border-b border-color-red"
            >
              <div class="mx-5 mb-4 flex items-center">
                <svg class="v-legend mr-2">
                  <rect
                    width="10"
                    height="10"
                    rx="2"
                    :style="{fill: '#003C5A'}"
                    />
                </svg>
                <span class="text-xs leading-none">{{ $t('voice.reports.summary[0]')}}</span>
              </div>
              <div class="flex">
                <div class="w-1/2 mx-5">
                  <p class="text-xs text-grey-dark">{{ $t('column_labels.inbound_calls') }}</p>
                  <p v-if="summary.Incoming" class="mt-1 text-sm text-black font-semibold">
                    {{ Number(summary.Incoming.calls).toLocaleString() }}
                  </p>
                  <p v-else class="mt-1 text-sm text-grey-dark font-semibold">0</p>
                </div>
                <div class="w-1/2 mx-5">
                  <p class="text-xs text-grey-dark">{{ $t('column_labels.call_duration') }}</p>
                  <p v-if="summary.Incoming" class="mt-1 text-sm text-black font-semibold">
                    {{ $vc.formatDurationSeconds(summary.Incoming.durationSeconds) }}
                  </p>
                  <p v-else class="mt-1 text-sm text-grey-dark font-semibold">{{ $t('app_labels.none') }}</p>
                </div>
              </div>
            </li>

            <!-- Outbound -->
            <li class="pb-5 my-5">
              <div class="mx-5 mb-4 flex items-center">
                <svg class="v-legend mr-2">
                  <rect
                    width="10"
                    height="10"
                    rx="2"
                    :style="{fill: '#1FA8D9'}"
                    />
                </svg>
                <span class="text-xs leading-none">{{ $t('voice.reports.summary[1]')}}</span>
              </div>
              <div class="flex">
                <div class="w-1/2 mx-5">
                  <p class="text-xs text-grey-dark">{{ $t('column_labels.outbound_calls') }}</p>
                  <p v-if="summary.Outgoing" class="mt-1 text-sm text-black font-semibold">
                    {{ Number(summary.Outgoing.calls).toLocaleString() }}
                  </p>
                  <p v-else class="mt-1 text-sm text-grey-dark font-semibold">0</p>
                </div>
                <div class="w-1/2 mx-5">
                  <p class="text-xs text-grey-dark">{{ $t('column_labels.call_duration') }}</p>
                  <p v-if="summary.Outgoing" class="mt-1 text-sm text-black font-semibold">
                    {{ $vc.formatDurationSeconds(summary.Outgoing.durationSeconds) }}
                  </p>
                  <p v-else class="mt-1 text-sm text-grey-dark font-semibold">{{ $t('app_labels.none') }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment';
import { mapState } from 'vuex';
import LineChart from './ReportChart.vue';

export default {
  name: 'Summary',

  components: {
    LineChart,
  },

  props: {
    tabList: {
      type: Array,
      default: () => [],
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    summary: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  data() {
    return {
      chartOption: {
        legend: false,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                color: '#ECECEC',
              },
              ticks: {
                min: 0,
                autoskip: true,
                autoSkipPadding: 10,
              },
              stacked: false,
              display: true,
              beginAtZero: true,
              scaleLabel: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                color: '#ECECEC',
              },
              ticks: {
                suggestedMax: 5,
                beginAtZero: true,
                precision: 0,
                callback(value) {
                  return value >= 1000 ? `${(value / 1000).toFixed((value / 1000) % 1 ? 1 : 0)}K` : value;
                },
              },
            },
          ],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
      },
    };
  },

  methods: {
    formatDateLabel(d, isStart = false) {
      const dt = isStart ? Moment(d).startOf('day') : Moment(d).endOf('day');

      let date = Moment(dt).format('MMMM DD, YYYY');
      if (this.getTimeInterval() === 60) {
        date = Moment(dt).format('MMMM DD, YYYY HH:mm');
      }
      return `${date} ${this.timeZone}`;
    },

    getTimeInterval() {
      const { daterange } = this.filters;
      const interval = daterange[1] - daterange[0];
      return interval <= 86399999 ? 60 : 1440;
    },
  },
};
</script>

<style lang="scss">
.chart {
  min-height: 250px;
}

.v-legend {
  width: 10px;
  height: 10px;
}
</style>
