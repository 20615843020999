<!-- eslint-disable max-len -->
<template>
  <el-dialog title="Export logs" :visible.sync="showModal" @close="close()" width="60%">
    <div class="flex flex-col">
      <div class="mb-4 flex">
        <el-dropdown
          ref="dateRange"
          trigger="click"
          size="small"
          type="primary"
          class="filter-item"
          @visible-change="handleDateVisibility"
          style="padding-left: 0 !important;"
        >
          <div
            class="px-4 h-full flex items-center justify-between font-normal text-sm"
            style="background-color: #CCDDF9; border-radius: .4rem;"
          >
            <span class="mr-2" style="color: #5b5b5b">
              <span>Date range </span>
              <strong>is </strong>
              <span>{{ `${formatDate(dates[0])} – ${formatDate(dates[1])}` }}</span>
            </span>
            <i class="icon-novo-arrow-down text-base font-semibold" />
          </div>
          <el-dropdown-menu
            slot="dropdown"
            class="invisible"
          >
            <CustomPicker
              :show-tz="false"
              :loading="loading"
              :user="user"
              :date-range="dateRange"
              :showPicker="showDatePicker"
              @change="dateRangeHandler"
            />
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown
          class="flex items-center filter-item"
          trigger="click"
          size="small"
          type="primary"
          :hide-on-click="false"
          @command="updateSelection"
        >
          <div
            class="px-4 h-full flex items-center font-normal text-sm"
            :style="{
              'background-color': selectedSubaccounts.length ? '#CCDDF9' : '#F1F1F1',
              'border-radius': '.4rem'
            }"
          >
            <span class="mr-2" style="color: #5b5b5b">
              <span v-if="selectedSubaccounts.length">
                <span>Subaccount </span>
                <strong>is </strong>
                <span>{{ selectedSubaccounts[0].name }}</span>
              </span>
              <span v-else>Subaccount</span>
            </span>
            <div class="flex items-center">
              <span v-if="selectedSubaccounts.length > 1" class="font-semibold mr-1">+{{ selectedSubaccounts.length - 1 }}</span>
              <i class="icon-novo-arrow-down text-base font-semibold" />
            </div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <div class="px-2 py-1">
              <el-input
                v-model="searchSubaccount"
                class="w-full"
                prefix-icon="icon-novo-search text-xl"
                placeholder="Search filter"
              />
            </div>
            <div class="filter-options">
              <div v-if="!filteredSubaccounts.length" class="flex justify-center p-3 text-sm text-grey">
                No matching data
              </div>
              <el-dropdown-item
                v-for="item in filteredSubaccounts"
                :key="item.value"
                :command="item"
                class="flex justify-between items-center"
              >
                {{ item.name }}
                <i
                  v-if="isSubaccountSelected(item.value)"
                  class="icon-novo-check text-lg font-semibold"
                />
              </el-dropdown-item>
            </div>
          </el-dropdown-menu>
        </el-dropdown>

        <!-- <el-select
          v-model="selectedSubaccounts"
          multiple
          collapse-tags
          class="w-2/5"
          placeholder="Select subaccounts"
        >
          <span slot="prefix">Test</span>
          <el-option
            v-for="item in subaccounts"
            :key="item.id"
            :label="item.name"
            :value="item.value">
          </el-option>
        </el-select> -->
      </div>
      <div class="mb-4" style="word-break: break-word;">A download link will be sent to the email address below when the export is ready. You can add another email address if needed.</div>
      <el-input
        readonly
        :value="user.Email"
        class="mb-2 w-1/2 pointer-events-none"
      />
      <el-form
        ref="emailForm"
        name="email"
        :model="emailForm"
        :rules="rules"
        @submit.native.prevent
      >
        <el-form-item prop="email">
          <el-input
            v-model="emailForm.email"
            placeholder="Add another email address"
            class="mb-2 w-1/2"
          />
        </el-form-item>
      </el-form>
      <div
        class="border rounded border-grey-lighter flex py-4 pr-4"
      >
        <div class="w-1 bg-grey-darker rounded mr-3" style="height: 75%" />
        <i class="icon-novo-info-circle mr-2 text-xl" />
        <span class="self-center" style="word-break: break-word;">You cannot download log data for the current day. Logs will be available for download the next day</span>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()" type="text" class="mr-4">Cancel</el-button>
      <el-button type="primary" :disabled="!selectedSubaccounts.length" @click="sendDownloadLink()">Send download link</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Moment from 'moment';
import CustomPicker from '../../partials/CustomPicker.vue';

export default {
  components: {
    CustomPicker,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    closeModal: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    const checkEmail = (rule, value, callback) => {
      const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (value.trim() !== '' && !value.match(emailRegex)) {
        callback(new Error(this.$t('validations.valid', { value: this.$t('fields.email') })));
        return;
      }

      if (value.trim() !== '' && value === this.user.Email) {
        callback(new Error(this.$t('messaging.reports.export_modal[6]')));
        return;
      }

      callback();
    };

    return {
      emailForm: {
        email: '',
      },
      rules: {
        email: [
          {
            validator: checkEmail,
            trigger: 'click',
            transform(value) {
              return value.trim();
            },
          },
        ],
      },

      showDatePicker: false,
      dates: [],
      dateRange: {},
      defaultDateRange: [
        Moment().startOf('day').subtract(1, 'w').valueOf(),
        Moment().endOf('day').valueOf(),
      ],

      searchSubaccount: '',
      filteredSubaccounts: [],
      selectedSubaccounts: [],
    };
  },

  created() {
    this.dates = this.defaultDateRange;
    this.dateRange = { from: this.dates[0], to: this.dates[1] };
  },

  computed: {
    ...mapGetters({
      subaccountList: 'common/getsubaccounts',
      user: 'user/getUser',
    }),

    ...mapState('user', ['timeZoneOffset']),

    subaccounts() {
      return this.subaccountList.map(s => ({
        id: s.SubAccountUid,
        name: s.SubAccountId,
        value: s.SubAccountId,
      }));
    },
  },

  watch: {
    subaccounts() {
      this.selectedSubaccounts = this.subaccounts;
      this.filteredSubaccounts = this.subaccounts;
    },
    searchSubaccount(v) {
      this.filteredSubaccounts = this.subaccounts.filter(
        p => p.name.toLowerCase().includes(v.toLowerCase()),
      );
    },
  },

  methods: {
    ...mapActions({
      exportVoiceLogs: 'logs/exportVoiceLogs',
    }),

    close() {
      this.emailForm.email = '';
      this.dates = this.defaultDateRange;
      this.selectedSubaccounts = this.subaccounts;
      this.$emit('close-modal');
    },

    sendDownloadLink() {
      this.$refs.emailForm.validate((valid) => {
        if (!valid) return;

        this.exportVoiceLogs({
          additionalEmail: this.emailForm.email,
          dateFrom: Moment(this.dates[0]).format('YYYY-MM-DD'),
          dateTo: Moment(this.dates[1]).format('YYYY-MM-DD'),
          utcOffset: this.timeZoneOffset,
          subAccounts:
            this.selectedSubaccounts.map(s => ({ SubAccountUid: s.id, SubAccountId: s.value })),
        });

        this.$notify({
          type: 'success',
          message: 'Your request is being processed. A download link will be sent to the specified email address once the export is ready.',
        });

        this.close();
      });
    },

    isSubaccountSelected(value) {
      return this.selectedSubaccounts.some(f => f.value === value);
    },

    updateSelection(subaccount) {
      const { value } = subaccount;

      if (this.isSubaccountSelected(value)) {
        this.selectedSubaccounts = this.selectedSubaccounts.filter(sa => sa.value !== value);
      } else {
        this.selectedSubaccounts.push(subaccount);
      }
    },

    handleDateVisibility(value) {
      this.showDatePicker = value;
    },

    formatDate(date) { // 01 Jan 2025
      return Moment(date).format('DD MMM YYYY');
    },

    dateRangeHandler(d) {
      this.dates = [Moment(d.dateFrom).valueOf(), Moment(d.dateTo).valueOf()];

      console.log({ a: Moment(this.dates[0]).format() });
      console.log({ b: Moment(this.dates[1]).format() });
      console.log({ aNum: this.dates[0] });
      console.log({ bNum: this.dates[1] });
      console.log({ unix: Moment(this.dates[0]).unix() });
      console.log({ unix: Moment(this.dates[0]).utcOffset(0, true).unix() });
    },
  },
};
</script>

<style scoped>
.filter-item {
  padding: 0.25rem;
  height: 2.75rem;
}

.filter-options {
  max-height: 250px;
  overflow-y: auto;
}

.el-dropdown-menu--small .el-dropdown-menu__item {
  padding: 0.5rem 1rem;
}
</style>
