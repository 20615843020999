<template>
  <div class="flex flex-col text-xs flex-1">
    <span class="font-normal mb-1">{{ fieldName }}</span>
    <span v-if="fieldValue" class="font-semibold">
      {{ fieldValue }}
    </span>
    <slot v-else></slot>
  </div>
</template>

<script>
export default {
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    fieldValue: {
      type: String,
      required: false,
    },
  },
};
</script>
