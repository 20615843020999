<template>
  <div>
    <div
    v-if="fieldValue"
    class="text-xs rounded-full px-2 py-1 flex items-center"
    :style="{
      width: 'fit-content',
      backgroundColor: `${fieldValue === 'COMPLETED' ? '#CCDDF9' : '#F5D3D6'}`
    }"
  >
    <i :class="`${formatStatus(fieldValue).icon} text-base mr-1`" />
    <span>{{ formatStatus(fieldValue).name }}</span>
  </div>
  <div v-else class="text-xs text-grey-dark">—</div>
  </div>
</template>

<script>
import callStatusesJson from '@/json/callStatuses.json';

export default {
  props: {
    fieldValue: {
      type: String,
      default: '',
    },
  },

  methods: {
    formatStatus(status) {
      const statusObj = callStatusesJson.find(s => s.dbValue === status);
      const { icon = '', name = '' } = statusObj || {};
      return { icon, name };
    },
  },
};
</script>
