<template>
  <div>
    <div class="flex w-full text-xs">
      <div class="w-full">
        <div class="flex">
          <h2 class="text-base font-semibold">{{ $t('date_labels.daily_report') }}</h2>
          <div
            v-if="!filters.type.match(/vm/i)"
            class="ml-10 flex items-center"
          >
            <svg class="v-legend mr-2">
              <rect
                width="10"
                height="10"
                rx="2"
                fill="#ced9df"
                />
            </svg>
            <span class="text-xs text-grey-dark">{{ $t('column_labels.inbound') }}</span>
          </div>
          <div class="ml-5 flex items-center">
            <svg class="v-legend mr-2">
              <rect
                width="10"
                height="10"
                rx="2"
                fill="#bfe4f4"
                />
            </svg>
            <span class="text-xs text-grey-dark">{{ $t('column_labels.outbound') }}</span>
          </div>
        </div>
        <div class="w-full">
          <el-table
            :data="tableData"
            size="small"
            class="w-full mt-5"
            fit
          >
            <!-- Date -->
            <el-table-column
              :label="tableRangeType()"
              label-class-name="column"
              v-slot="scope"
            >
              <div v-if="scope.row.Date"
                class="text-xs truncate"
              >
                {{ formatDate(scope.row.Date) }}
              </div>
              <div v-else class="text-xs truncate text-grey-dark">—</div>
            </el-table-column>

            <!-- Calls -->
            <el-table-column
              :label="$t('column_labels.calls')"
              label-class-name="column"
              width="100px"
              align="right"
              v-slot="scope"
            >
              <div v-if="scope.row.Calls"
                class="text-xs truncate"
              >
                {{ Number(scope.row.Calls).toLocaleString() }}
              </div>
              <div v-else class="text-xs truncate text-grey-dark">0</div>
            </el-table-column>

            <!-- Inbound -->
            <el-table-column
              v-if="!filters.type.match(/vm/i)"
              :label="$t('column_labels.inbound_calls')"
              label-class-name="column column--inbound"
              align="right"
              v-slot="scope"
            >
              <div v-if="scope.row.Incoming"
                class="text-xs truncate"
              >
                {{ Number(scope.row.Incoming).toLocaleString() }}
              </div>
              <div v-else class="text-xs truncate text-grey-dark">0</div>
            </el-table-column>

            <!-- Duration -->
            <!-- <el&#45;table&#45;column -->
            <!--   v&#45;if="!filters.type.match(/vm/i)" -->
            <!--   label="Duration" -->
            <!--   label&#45;class&#45;name="column column&#45;&#45;inbound" -->
            <!--   v&#45;slot="scope" -->
            <!-- > -->
            <!--   <div v&#45;if="scope.row.IncomingSec" -->
            <!--     class="text&#45;xs truncate" -->
            <!--   > -->
            <!--     {{ $vc.formatDurationSeconds(scope.row.IncomingSec) }} -->
            <!--   </div> -->
            <!--   <div v&#45;else class="text&#45;xs truncate text&#45;grey&#45;dark">—</div> -->
            <!-- </el&#45;table&#45;column> &#45;&#45;> -->

            <!-- Chargeable duration -->
            <el-table-column
              v-if="!filters.type.match(/vm/i)"
              :label="$t('column_labels.chargeable_duration')"
              label-class-name="column column--inbound"
              align="center"
              v-slot="scope"
            >
              <div v-if="scope.row.IncomingChargeableSec"
                class="text-xs truncate font-bold"
              >
                {{ $vc.formatDurationSeconds(scope.row.IncomingChargeableSec) }}
              </div>
              <div v-else class="text-xs truncate text-grey-dark">—</div>
            </el-table-column>

            <!-- Outbound calls -->
            <el-table-column
              :label="$t('column_labels.outbound_calls')"
              label-class-name="column column--outbound"
              align="right"
              v-slot="scope"
            >
              <div v-if="scope.row.Outgoing"
                class="text-xs truncate"
              >
                {{ Number(scope.row.Outgoing).toLocaleString() }}
              </div>
              <div v-else class="text-xs truncate text-grey-dark">0</div>
            </el-table-column>

            <!-- Duration -->
            <!-- <el&#45;table&#45;column -->
            <!--   label="Duration" -->
            <!--   label&#45;class&#45;name="column column&#45;&#45;outbound" -->
            <!--   v&#45;slot="scope" -->
            <!-- > -->
            <!--   <div v&#45;if="scope.row.OutgoingSec" -->
            <!--     class="text&#45;xs truncate" -->
            <!--   > -->
            <!--     {{ $vc.formatDurationSeconds(scope.row.OutgoingSec) }} -->
            <!--   </div> -->
            <!--   <div v&#45;else class="text&#45;xs truncate text&#45;grey&#45;dark">—</div> -->
            <!-- </el&#45;table&#45;column> &#45;&#45;> -->

            <!-- Chargeable duration -->
            <el-table-column
              :label="$t('column_labels.chargeable_duration')"
              label-class-name="column column--outbound"
              align="center"
              v-slot="scope"
            >
              <div v-if="scope.row.OutgoingChargeableSec"
                class="text-xs truncate font-bold"
              >
                {{ $vc.formatDurationSeconds(scope.row.OutgoingChargeableSec) }}
              </div>
              <div v-else class="text-xs truncate text-grey-dark">—</div>
            </el-table-column>

          </el-table>
        </div>
      </div>
    </div>
    <div class="text-right r-pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="data.length"
      />
    </div>
  </div>
</template>

<script>
import Moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: 'Table',

  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      page: 1,
      pageSize: 10,
    };
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),

    tableData() {
      const results = this.data;

      const start = this.page * this.pageSize - this.pageSize;
      const end = start + this.pageSize;

      return results.slice(start, end);
    },
  },

  watch: {
    filters() {
      this.page = 1;
    },
  },

  methods: {
    handleCurrentChange(page) {
      this.page = Number(page);
    },

    tableRangeType() {
      const start = this.filters.daterange[0];
      const end = this.filters.daterange[1];
      return Moment(start).isSame(end, 'days') ? this.$t('column_labels.time') : `${this.$t('column_labels.date')} ${this.timeZone}`;
    },

    formatDate(dt) {
      let d = '';
      const start = this.filters.daterange[0];
      const end = this.filters.daterange[1];
      if (!Moment(start).isSame(end, 'days')) {
        if (Moment().isSame(dt, 'day')) {
          d = this.$t('date_labels.today');
        } else {
          d = Moment(dt).format('MMM DD YYYY');
        }
      } else {
        d = Moment(dt).format('HH:ss');
      }
      return d;
    },
  },
};
</script>

<style lang="scss">
.r-pagination {
   background-color: #E4EDFF;

  .el-pagination {
    padding-top: 0;
    padding-bottom: 0;

    button:disabled, button, ul>li {
      background-color: #f2f5ff;
    }

    button, ul>li {
      color: #8795a1;
    }

    button:disabled {
      color: #cccccc;
    }

    button, ul>li, span {
      height: 36px;
    }

    span, ul>li {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    ul>li.active {
      background: white;
    }
  }
}

.v-legend {
  width: 10px;
  height: 10px;
}

.column {
  &--inbound {
    z-index: 1;
    color: black !important;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #ced9df;
      border-left: solid 1px white;
      z-index: -1;
    }
  }

  &--outbound {
    z-index: 1;
    color: black !important;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #bfe4f4;
      border-left: solid 1px white;
      z-index: -1;
    }
    background: #bfe4f4;
  }
}
</style>
